<template>
  <div
    class="d-flex align-center flex-nowrap"
    :class="{ 'topic-ref':!dense, 'dense': dense}"
  >
    <document-ref-icon
      :visible="showIcon"
      :icon-name="iconName"
      :mixed="mixed"
    />
    <div class="d-flex flex-column flex-nowrap overflow-x-hidden">
      <div class="d-flex">
        <span class="mr-1">{{$t('t.Number#')}}</span>
        <span class="mr-2">{{number}}</span>
        <span class="text-truncate">{{subject}}</span>
      </div>
      <slot name="doc-type" />
    </div>
  </div>
</template>

<script>
import ClientCache from '@/mixins/client-cache'

export default {
  inheritAttrs: false,
  components: {
    DocumentRefIcon: () => import('@/components/document-ref-icon')
  },
  computed: {
    number () {
      return this.cache?.doc?.id
    },
    subject () {
      return this.id ? this.cache?.doc?.subject : this.$t('t.NewTopic')
    },
    cacheType () {
      return ClientCache.CacheType.TopicRef
    }
  },
  mixins: [ClientCache],
  props: {
    id: Number,
    options: Object,
    mixed: Boolean,
    showIcon: Boolean,
    iconName: String,
    dense: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.topic-ref
  max-width 500px

.dense
  max-width 300px
</style>
